<template>
  <section class="admin">
    <div class="col1">
      <div class="adminSidebar">
        <ul>
          <li>
            <router-link to="/admin/publicar-versiculo" exact>Versículo de la semana</router-link>
          </li>
          <li>
            <router-link to="/admin/semana-activa" exact>Semana Activa</router-link>
          </li>
          <li>
            <router-link to="/admin/publicar" exact>Publicar Devocional</router-link>
          </li>
          <li>
            <router-link to="/admin/devocionales" exact>Devocionales</router-link>
          </li>
          <li>
            <router-link to="/admin/usuarios" exact>Usuarios</router-link>
          </li>
          <li>
            <router-link to="/admin/reporte" exact>Reporte</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="col2">
      <div class="adminContent">
        <h1>Admin</h1>
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    if (!this.$store.getters.isAdmin) {
      this.$router.push('/')
    }
    this.$store.dispatch('fetchForms')
    this.$store.dispatch('fetchPosts')
    this.$store.dispatch('fetchUsers')
  }
}
</script>

<style lang="scss" scoped>
.admim {
  padding: 3rem 0 3rem;
}
.adminContent {
  background-color: #ffffff;
  padding: 2em;
}

.col2 {
  flex: 0 0 70%;
}
</style>